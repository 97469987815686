<template>
  <div>
    <ed-email :intCabecalhoMovimentoId="0" :intTipoMovimentoId="1443" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdEmail from "@/components/movimento/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdEmail },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>